import React from 'react';

const ReviewTwo = ({ reqPage }) => {
  return (
    <>
      <div className='col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0'>
        <div className='testimonial-tab-slider-wrap mt-5'>
          {reqPage ? (
            <h1 className='fw-bold text-white display-5'>
              Lleva a tu empresa al siguiente nivel
            </h1>
          ) : (
            <h2 className='text-white'>Lleva a tu empresa al siguiente nivel</h2>
          )}
          {/* <p>
            SOE le ofrece la seguridad y la confianza que necesita para tomar decisiones informadas y ejecutar planes estratégicos con precisión.
          </p> */}
          {/* <hr /> */}
          <div
            className='tab-content testimonial-tab-content text-white-80'
            id='pills-tabContent'
          >
            <div
              className='tab-pane fade show active'
              id='testimonial-tab-1'
              role='tabpanel'
            >
              <blockquote className='blockquote'>
                <em>
                  "SOE asegura que cada tarea y su impacto sean monitoreados y evaluados continuamente, evitando así el tropezar con la misma piedra una y otra vez. "
                </em>
              </blockquote>
              <div className='author-info mt-3'>
                <span className='h6'>Alberto Rigail</span>
                <span className='d-block small'>CEO ARCO ESTRATEGIAS</span>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='testimonial-tab-2'
              role='tabpanel'
            >
              <blockquote className='blockquote'>
                <em>
                  "SOE, un "upper case" que conecta los objetivos de las variables críticas y KPis del plan estratégico de la empresa con la ejecucion y con las funciones responsables. De esta manera, nada se queda sin medir ni fuera de control."
                </em>
              </blockquote>
              <div className='author-info mt-3'>
                <span className='h6'>Eduardo Jurado</span>
                <span className='d-block small'>CHAIRMAN SEMGROUP</span>
              </div>
            </div>
          </div>
          <ul
            className='nav nav-pills mb-0 testimonial-tab-indicator mt-5'
            id='pills-tab'
            role='tablist'
          >
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link active'
                data-bs-toggle='pill'
                data-bs-target='#testimonial-tab-1'
                type='button'
                role='tab'
                aria-selected='true'
              >
                <img
                  src='assets/img/testimonial/perfil_alberto.jpeg'
                  alt='testimonial'
                  width='55'
                  className='img-fluid rounded-circle'
                />
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link'
                data-bs-toggle='pill'
                data-bs-target='#testimonial-tab-2'
                type='button'
                role='tab'
                aria-selected='false'
              >
                <img
                  src='assets/img/testimonial/perfil_eduardo.jpg'
                  alt='testimonial'
                  width='55'
                  className='img-fluid rounded-circle'
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ReviewTwo;
