import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgContentThreeCopy from '../../components/feature-img-content/FeatureImgContentThreeCopy';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgEightCopy from '../../components/features/FeatureImgEightCopy';
import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureTwo from '../../components/features/FeatureTwo';

const SingleService = () => {
  return (
    <Layout>
      <PageMeta title="Conócenos" />
      <Navbar navDark />
      <PageHeader
        title="¿Qué es SOE?"
        desc="SOE es una herramienta revolucionaria diseñada para brindar control y seguimiento efectivo a la estrategia de tu empresa. "
      />
      <FeatureImgEight paddingTop />
      <FeatureImgContentThree />
      <FeatureImgEightCopy />
      <FeatureImgContentThreeCopy />
      <FeatureTwo />
      <RequestForDemo />
      <FooterOne footerGradient />
    </Layout>
  );
};

export default SingleService;
