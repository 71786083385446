import React, { Component } from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroOne from './HeroOne';
import PageMeta from '../../components/common/PageMeta';
import WorkProcess from '../../components/work-process/WorkProcess';
import CtaOne from '../../components/cta/CtaOne';
import FooterOne from '../../layout/Footer/FooterOne';
import Promo from '../../components/promo/Promo';
import TestimonialFive from '../../components/testimonial/TestimonialFive';


import FeatureImgThree from '../../components/features/FeatureImgThree';


class HomeSassOne extends Component {
  render() {
    return (
      <Layout>
        <PageMeta title='Inicio' />
        <Navbar navDark />
        <HeroOne />
        <Promo />
        <FeatureImgThree paddingTop />
        <WorkProcess />
        <CtaOne />
        {/* <TestimonialFive/> */}
        <FooterOne footerGradient />
      </Layout>
    );
  }
}

export default HomeSassOne;
