import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import HeroTitle from "../../components/common/HeroTitle";

const HeroOne = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <section className="hero-section ptb-120 text-white bg-gradient">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-xl-5 col-lg-5 mt-5">
              <div
                className="hero-content-wrap text-center text-xl-start text-lg-start mt-5"
                data-aos="fade-right"
              >
                <HeroTitle
                  title="Mejora el Performance de tu empresa con SOE® "
                  desc="El Sistema Operativo de Estrategia potencia tu gestión, te permite monitorear, 
                        controlar y medir el desempeño de tus colaboradores, incrementando 
                        sus resultados y facilitando logros de objetivos estratégicos."
                />
                <ul className="nav subscribe-feature-list mt-5 fw-bold">
                  <li className="nav-item">
                    <span className="ms-0">
                      <i className="far fa-check-circle text-primary me-2"></i>
                      Integra las metodologías SMC® y PMS®
                    </span>
                  </li>
                  <li className="nav-item">
                    <span className="ms-0">
                      <i className="far fa-check-circle text-primary me-2"></i>
                      Acceso a la información 24/7
                    </span>
                  </li>
                  <li className="nav-item">
                    <span className="ms-0">
                      <i className="far fa-check-circle text-primary me-2"></i>
                      Soporte de las metodologías y la plataforma
                    </span>
                  </li>
                  <li className="nav-item">
                    <span className="ms-0">
                      <i className="far fa-check-circle text-primary me-2"></i>
                      Seguridad y Eficiencia
                    </span>
                  </li>
                </ul>
                <div
                  className="action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <ModalVideo
                    channel="youtube"
                    isOpen={isOpen}
                    videoId="MB30D6OQ9fo"
                    onClose={() => setOpen(false)}
                  />
                  <Link to="/request-for-demo" className="btn btn-primary me-3">
                    Solicitar Demo
                  </Link>
                  <Link
                    to="#"
                    className="text-white text-decoration-none d-inline-flex align-items-center watch-now-btn mt-3 mt-lg-0 mt-md-0"
                    onClick={() => setOpen(true)}
                  >
                    <i className="fas fa-play"></i> Ver Demo
                  </Link>
                </div>

                {/* <div className="row justify-content-lg-start mt-60">
                  <h6 className="text-white-70 mb-2">Our Top Clients:</h6>
                  <div className="col-4 col-sm-3 my-2 ps-lg-0">
                    <img
                      src="assets/img/clients/client-1.svg"
                      alt="client"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-4 col-sm-3 my-2">
                    <img
                      src="assets/img/clients/client-2.svg"
                      alt="client"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-4 col-sm-3 my-2">
                    <img
                      src="assets/img/clients/client-3.svg"
                      alt="client"
                      className="img-fluid"
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 mt-4 mt-xl-0">
              <div
                className="hero-img-wrap position-relative"
                data-aos="fade-left"
              >
                <ul className="position-absolute animate-element parallax-element shape-service hide-medium">
                  <li className="layer" data-depth="0.03">
                    <img
                      src="assets/img/shape/shape-gray-dark.svg"
                      alt="shape"
                      className="img-fluid position-absolute color-shape-1"
                    />
                  </li>
                  <li className="layer" data-depth="0.02">
                    <img
                      src="assets/img/shape/shape-gray-dark.svg"
                      alt="shape"
                      className="img-fluid position-absolute color-shape-2"
                    />
                  </li>
                  <li className="layer" data-depth="0.03">
                    <img
                      src="assets/img/shape/shape-gray-dark.svg"
                      alt="shape"
                      className="img-fluid position-absolute color-shape-3"
                    />
                  </li>
                </ul>

                <div className="hero-img-wrap position-relative">
                  <div className="hero-screen-wrap">
                    <div className="mac-screen">
                      <img
                        src="assets/img/screen/mac-screen.png"
                        alt="hero "
                        className="position-relative img-fluid rounded-custom"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroOne;
