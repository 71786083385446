import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ContactsForm = () => {
  const [formData, setFormData] = useState({
    nombres: '',
    apellidos: '',
    cargo: '',
    empresa: '',
    email: '',
    telefono: '',
    mensaje: '',
    marketingConsent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://soe-api-email-production.up.railway.app/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert('¡Mensaje enviado con éxito!');
      } else {
        alert('Hubo un error al enviar el mensaje. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Hubo un error al enviar el mensaje. Por favor, inténtalo de nuevo más tarde.');
    }
  };

  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Rellena el formulario y nos pondremos en contacto lo antes posible.
          </h3>

          <form onSubmit={handleSubmit} className='mt-4 register-form'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Nombres'
                    name='nombres'
                    value={formData.nombres}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Apellidos'
                    name='apellidos'
                    value={formData.apellidos}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Cargo'
                    name='cargo'
                    value={formData.cargo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Empresa'
                    name='empresa'
                    value={formData.empresa}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='email'
                    className='form-control'
                    placeholder='Email'
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='Teléfono'
                    name='telefono'
                    value={formData.telefono}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='input-group mb-3'>
                  <textarea
                    className='form-control'
                    placeholder='Cuéntanos más sobre tu empresa, necesidades o dudas.'
                    style={{ height: '120px' }}
                    name='mensaje'
                    value={formData.mensaje}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className='col-12'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckChecked'
                    name='marketingConsent'
                    checked={formData.marketingConsent}
                    onChange={handleChange}
                  />
                  <label
                    className='form-check-label small'
                    htmlFor='flexCheckChecked'
                  >
                    Sí, me gustaría recibir correos electrónicos de marketing ocasionales de SOE. Tengo derecho a optar por no participar en cualquier momento.
                    <Link to='#'>Ver política de privacidad</Link>.
                  </label>
                </div>
              </div>
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary mt-4 d-block w-100'
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactsForm;
