import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import CtaSubscribe from '../components/cta/CtaSubscribe';
import FaqTwo from '../components/faqs/FaqTwo';
import PriceOne from '../components/prices/PriceThree';
import TestimonialFive from '../components/testimonial/TestimonialFive';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import CtaOne from '../components/cta/CtaOne';

const Pricing = () => {
  return (
    <Layout>
      <PageMeta title='Precios - SOE' />
      <Navbar />
      {/* <PageHeader
        title='Invierte en tu éxito con SOE'
        desc='Elige el plan que mejor se adapte a tus necesidades y comienza a transformar tu estrategia hoy mismo."'
      /> */}
      <PriceOne paddingTop='ptb-120'/>
      {/* <FaqTwo /> */}
      <TestimonialFive/>
      {/* <CtaSubscribe /> */}
      {/* <FooterOne footerLight /> */}
      <CtaOne />
      <FooterOne footerGradient />
    </Layout>
  );
};

export default Pricing;
