import React from 'react';
import SectionTitle from '../common/SectionTitle';

const WorkProcess = ({bgWhite}) => {
  return (
    <>
      <section className={`promo-section ptb-120 ${bgWhite?'bg-white':'bg-light'} `}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                subtitle='Proceso'
                title='Estrategia SOE en Acción'
                description='Descubre un camino claro y efectivo hacia el éxito con cada módulo de SOE, diseñado para guiarte paso a paso en la implementación de estrategias que impulsan resultados óptimos.'
                centerAlign
              />
            </div>
          </div>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12 order-1 order-lg-0'>
              <div className='img-wrap'>
                <img
                  src='assets/img/office-img-11.jpg'
                  alt='work process'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <ul className='work-process-list list-unstyled'>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-folder-tree fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Paso 1</span>
                    <h3 className='h5 mb-2'>Plan Estratégico</h3>
                    <p>
                    Centraliza el plan estratégico de la empresa, permitiendo acceso fácil y constante para todos los miembros del equipo.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-bezier-curve fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Paso 2</span>
                    <h3 className='h5 mb-2'>Posiciones</h3>
                    <p>
                    Ofrece seguimiento en tiempo real de proyectos clave con responsables asignados y seguimiento de avances preciso.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-layer-group fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Paso 3</span>
                    <h3 className='h5 mb-2'>Proyectos Estratégicos</h3>
                    <p>
                    Visualiza las definiciones de cargo de los colaboradores alineados a la estrategia.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4 mb-lg-0'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-truck fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Paso 4</span>
                    <h3 className='h5 mb-2'>Objetivos Estratégicos</h3>
                    <p>
                    Permite el seguimiento preciso de los objetivos estratégicos con indicadores de cumplimiento y un historial de logros.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4 mb-lg-0'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-truck fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Paso 5</span>
                    <h3 className='h5 mb-2'>Prioridades</h3>
                    <p>
                    Permite establecer prioridades personalizadas relacionadas con los objetivos estratégicos, con un seguimiento de prioridades cumplidas y pendientes.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4 mb-lg-0'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-truck fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Paso 6</span>
                    <h3 className='h5 mb-2'>Performance</h3>
                    <p>
                    Mide el rendimiento de cada miembro del equipo de manera precisa y efectiva, utilizando datos clave y análisis histórico de logros.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcess;
