import React from "react";

const PriceThree = () => {
  return (
    <section className="price-two ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="section-heading text-center">
              <h2>
              Invierte en tu éxito con SOE
              </h2>
              <p>
              Elige el plan que mejor se adapte a tu empresa y comienza a transformar tu estrategia hoy mismo.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">Plan 1 Año</h3>
                <h4 className="display-6 fw-semi-bold">
                  $9.000
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    10 de usuarios
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Actualización continua
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Gestión de actividad
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Soporte dedicado
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>{" "}
                    Resultados garantizados
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i> 40
                    Acceso completo
                  </li>
                </ul>
              </div>
              <a
                href="request-demo.html"
                className="btn btn-outline-primary mt-2"
              >
                Adquirir Ahora
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-dark text-white p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-warning d-block">Plan 2 Años</h3>
                <h4 className="display-6 fw-semi-bold">
                  $14.300
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    10 de usuarios
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Actualización continua
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Gestión de actividad
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Soporte dedicado
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>{" "}
                    Resultados garantizados
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i> 40
                    Acceso completo
                  </li>
                </ul>
              </div>
              <a href="request-demo.html" className="btn btn-primary mt-2">
                Adquirir Ahora
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
              <div className="pricing-header mb-32">
                <h3 className="package-name text-primary d-block">Plan 3 Años</h3>
                <h4 className="display-6 fw-semi-bold">
                $19.600
                </h4>
              </div>
              <div className="pricing-info mb-4">
                <ul className="pricing-feature-list list-unstyled">
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    10 de usuarios
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Actualización continua
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Gestión de actividad
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>
                    Soporte dedicado
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i>{" "}
                    Resultados garantizados
                  </li>
                  <li>
                    <i className="far fa-check-circle text-success me-2"></i> 40
                    Acceso completo
                  </li>
                </ul>
              </div>
              <a
                href="request-demo.html"
                className="btn btn-outline-primary mt-2"
              >
                Adquirir Ahora
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PriceThree;
