import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const PriceTwo = () => {
  return (
    <>
      <section
        className='pricing-section position-relative overflow-hidden bg-gradient text-white pt-120'
        style={{
          background: "url('img/page-header-bg.svg')no-repeat center center",
        }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <SectionTitle
                subtitle='Planes &amp; Precios'
                title='Invierte en tu éxito con SOE'
                description='Elige el plan que mejor se adapte a tu empresa y comienza a transformar tu estrategia hoy mismo.'
                dark
                centerAlign
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='pricing-content-wrap mb--100 bg-white rounded-custom shadow-lg border d-fle z-10 position-relative'>
                <div className='price-feature-col pricing-feature-info left-radius bg-primary-soft p-5'>
                  <ul
                    className='list-unstyled pricing-feature-list pricing-included-list mb-0'
                    data-aos='fade-left'
                  >
                    <li className='py-1'>
                      <i className='fas fa-check-circle text-primary me-2'></i>
                      10 usuarios por compañia
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle text-primary me-2'></i>
                      Acceso a todas las funciones
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle text-primary me-2'></i>
                      Capacitación de uso de la plataforma
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle text-primary me-2'></i>
                      Acompañamiento por 2 meses 
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle text-primary me-2'></i>
                      Gestión de actividad
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle text-primary me-2'></i>
                      Soporte con chat en vivo
                    </li>
                    <li className='py-1'>
                      <i className='fas fa-check-circle text-primary me-2'></i>
                      Notificaciones de Actividad
                    </li>
                  </ul>
                </div>
                <div
                  className='price-feature-col pricing-action-info p-5'
                  data-aos='fade-right'
                >
                  <ul
                    className='nav nav-pills mb-4 pricing-tab-list'
                    id='pills-tab'
                    role='tablist'
                  >
                    <li className='nav-item' role='presentation'>
                      <button
                        className='active'
                        id='pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-home'
                        type='button'
                        role='tab'
                        aria-controls='pills-home'
                        aria-selected='true'
                      >
                        Mes
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        id='pills-profile-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-profile'
                        type='button'
                        role='tab'
                        aria-controls='pills-profile'
                        aria-selected='false'
                        className=''
                      >
                        Año
                      </button>
                    </li>
                  </ul>
                  <div className='tab-content' id='pills-tabContent'>
                    <div
                      className='tab-pane fade active show'
                      id='pills-home'
                      role='tabpanel'
                      aria-labelledby='pills-home-tab'
                    >
                      <h3 className='h5'>Pro Mensual</h3>
                      <p>
                      Paga una cuota mensual sin compromiso a largo plazo.
                      </p>
                      <div className='pricing-price mt-5'>
                        <h4 className='h1 fw-bold'>
                          $750 <span>/Mes</span>
                        </h4>
                      </div>
                      <Link
                        to='/request-for-demo'
                        className='btn btn-primary mt-3'
                      >
                        Probar Demo
                      </Link>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='pills-profile'
                      role='tabpanel'
                      aria-labelledby='pills-profile-tab'
                    >
                      <h3 className='h5'>Pro Anual</h3>
                      <p>
                      Paga una vez al año y obtén un descuento del 15% por usuario
                      </p>
                      <div className='pricing-price mt-5'>
                        <h4 className='h1 fw-bold'>
                          $9.000 <span>/Año</span>
                        </h4>
                      </div>
                      <Link
                        to='/request-for-demo'
                        className='btn btn-primary mt-3'
                      >
                        Probar Demo
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-circle rounded-circle circle-shape-1 position-absolute bg-warning left-5'></div>
        <div className='white-space-100 bg-white w-100'></div>
      </section>
    </>
  );
};

export default PriceTwo;
