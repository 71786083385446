import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureTwo = ({ cardDark }) => {
  return (
    <>
      <section
        className={`feature-section ptb-120 ${
          cardDark ? 'bg-dark' : 'bg-light'
        }`}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              {cardDark ? (
                <SectionTitle
                  subtitle='¡Toma decisiones informadas y mide el éxito!'
                  title='S.O.E. te da el control'
                  centerAlign
                  dark
                />
              ) : (
                <SectionTitle
                subtitle='¡Toma decisiones informadas y visualiza el cumplimiento de tus objetivos!'
                  title='SOE te da el control'
                  centerAlign
                />
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='feature-grid'>
                <div
                  className={`shadow-sm highlight-card rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-primary-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-analytics icon-sm text-primary'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Toma el control estratégico</h3>
                    <p>
                    Define, ejecuta y monitorea tu estrategia con precisión. Mide el impacto real, no solo el seguimiento de tareas.
                    </p>
                    <h6 className='mt-4'>Llega al éxito con:</h6>
                    <ul className='list-unstyled mb-0'>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Visión estratégica completa
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Medición del impacto real
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Alineación perfecta
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Trabajo en equipo optimizado
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-file-chart-line icon-sm text-success'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Visión estratégica completa</h3>
                    <p className='mb-0'>
                    Obtén una perspectiva completa de tus metas para una gestión efectiva.
                    </p>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-danger-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-user-friends icon-sm text-danger'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Medición del impacto real</h3>
                    <p className='mb-0'>
                    Mide el rendimiento real, no solo el seguimiento de tareas.
                    </p>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-dark-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-spell-check icon-sm text-dark'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Alineación perfecta</h3>
                    <p className='mb-0'>
                    Asegura que cada acción impulse tus objetivos estratégicos.
                    </p>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-warning-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-cog icon-sm text-warning'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Trabajo en equipo optimizado</h3>
                    <p className='mb-0'>
                    Consolida las actividades y maximiza la colaboración.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTwo;
