import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";

SwiperCore.use([Navigation]);

const TestimonialFive = () => {
  const swiperOption = {
    slidesPerView: 3,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 2,
    loop: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 25,
      },
      1142: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: ".swiper-nav-control .swiper-button-next",
      prevEl: ".swiper-nav-control .swiper-button-prev",
    },
  };
  return (
    <section className="testimonial-section bg-light ptb-120">
      <div className="container">
        <div className="row justify-content-center align-content-center">
          <div className="col-md-10 col-lg-6">
            <div className="section-heading text-center" data-aos="fade-up">
              <h4 className="h5">Testimonios</h4>
              <h2>¿Qué Dicen las Empresas?</h2>
              <p>
              Conoce cómo SOE ha transformado a diversas empresas. Lee los testimonios de nuestros clientes satisfechos.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              className="position-relative w-100"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <Swiper {...swiperOption}>
                <SwiperSlide>
                  <div className="p-4 bg-white rounded-custom position-relative shadow-sm">
                    <p>
                    SOE asegura que cada tarea y su impacto sean monitoreados y evaluados continuamente, evitando así el tropezar con la misma piedra una y otra vez.  Soe saca a las empresas del agujero negro de la falta de rendimiento y las lleva a conseguir sus resultados
                    </p>

                    <div className="author d-flex">
                      <div className="author-img me-3">
                        <img
                          src="assets/img/testimonial/author1.jpg"
                          alt="author "
                          className="rounded-circle"
                          width="60"
                          height="60"
                        />
                      </div>
                      <div className="author-info">
                        <h6 className="m-0">Alberto Rigail</h6>
                        <span>CEO ARCO ESTRATEGIAS                        </span>
                        <ul className="review-rate mb-0 list-unstyled list-inline">
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                  <div className=" p-4 bg-white rounded-custom position-relative shadow-sm">
                    <p>
                    SOE convierte la teoría en práctica, ¡transformando cada estrategia en resultados visibles y medibles para nuestro equipo!
                    </p>

                    <div className="author d-flex">
                      <div className="author-img me-3">
                        <img
                          src="assets/img/testimonial/author3.jpg"
                          alt="author "
                          className="rounded-circle"
                          width="60"
                          height="60"
                        />
                      </div>
                      <div className="author-info">
                        <h6 className="m-0">Isabel Noboa</h6>
                        <span>CEO de Grupo Nobis</span>
                        <ul className="review-rate mb-0 list-unstyled list-inline">
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </SwiperSlide> */}
                <SwiperSlide>
                  <div className="swiper-slide p-4 bg-white rounded-custom position-relative shadow-sm">
                    <p>
                    SOE, un "upper case" que conecta los objetivos de las variables críticas y KPis del plan estratégico de la empresa con la ejecucion y con las funciones responsables. De esta manera, nada se queda sin medir ni fuera de control.
                    </p>

                    <div className="author d-flex">
                      <div className="author-img me-3">
                        <img
                          src="assets/img/testimonial/author2.jpg"
                          alt="author "
                          className="rounded-circle"
                          width="60"
                          height="60"
                        />
                      </div>
                      <div className="author-info">
                        <h6 className="m-0">Eduardo Jurado</h6>
                        <span>CHAIRMAN SEMGROUP</span>
                        <ul className="review-rate mb-0 list-unstyled list-inline">
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="fas fa-star text-warning"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="swiper-nav-control">
                <span className="swiper-button-next"></span>
                <span className="swiper-button-prev"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialFive;
