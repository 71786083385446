import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import ContactBox from '../components/contact/ContactBox';
import ContactFormTwo from '../components/contact/ContactFormTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
 
const Contact = () => {
  return (
    <Layout>
      <PageMeta title="Contáctanos" />
      <Navbar navDark />
      <PageHeader
        title="Contáctanos"
        desc="¿Tienes alguna pregunta o comentario? ¿Necesitas ayuda con algo específico? No dudes en ponerte en contacto con nosotros. ¡Estamos aquí para ayudarte!"
      />
      {/* <ContactBox /> */}
      <ContactFormTwo />
      <FooterOne footerGradient />
    </Layout>
  );
};

export default Contact;
