import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgThree = ({paddingTop}) => {
  return (
    <>
      <section className={`feature-section-two ${paddingTop?'ptb-120':'pt-60 pb-120'} `}>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12'>
              <SectionTitle
                subtitle='Características'
                title='Soluciones Potentes para las Necesidades de su Empresa'
                description='Una innovadora solución tecnológica que garantiza la transparencia en los resultados logrados por los colaboradores de tu organización.'
              />
              <div>
                <ul className='list-unstyled mt-5'>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className='fas fa-bezier-curve text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Simplificación y Agilización</h3>
                      <p>
                        Simplifica y agiliza el proceso de monitoreo y control de gestión.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-danger rounded me-4'>
                      <i className='fas fa-fingerprint text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Imputabilidad</h3>
                      <p>
                      Permite a los colaboradores llevar el registro y seguimiento de sus objetivos y prioridades.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className='fas fa-bezier-curve text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Evaluación Objetiva</h3>
                      <p>
                      Proporciona una manera objetiva de evaluar el desempeño de los colaboradores.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap position-relative d-flex flex-column align-items-end'>
                <ul className='img-overlay-list list-unstyled position-absolute'>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='50'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Transparencia</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='100'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Consistencia</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='150'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Estandarización</h6>
                  </li>
                </ul>
                <img
                  src='assets/img/feature-img4.jpeg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgThree;
